<template>
  <q-dialog
    backdrop-filter="grayscale(100%)"
    maximized
    ref="dialogRef"
    @hide="onHide"
  >
    <q-card class="transparent" flat square>
      <swiper-component :initial-slide="index" :slides="projects" />
      <div class="absolute-top-right q-ma-md" style="z-index: 1">
        <div class="q-ma-md">
          <q-btn
            dense
            color="primary"
            :icon="symSharpClose"
            :size="$q.screen.gt.sm ? 'md' : 'sm'"
            square
            text-color="white"
            unelevated
            @click="onOKClick()"
          />
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useDialogPluginComponent } from "quasar";
import { useAppStore } from "src/stores/app";
import { symSharpClose } from "@quasar/extras/material-symbols-sharp";
import SwiperComponent from "src/components/swiper/SwiperComponent.vue";

defineOptions({ name: "DialogProjects" });

const props = defineProps({
  data: { type: Object, default: () => {} },
  index: { type: Number, default: 0 },
  projects: { type: Array, default: () => [] },
});

defineEmits([...useDialogPluginComponent.emits]);

const appStore = useAppStore();
const { videoHasSound } = storeToRefs(appStore);
const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

function onOKClick() {
  onDialogOK();
}

function onHide() {
  onDialogHide();
  videoHasSound.value = false;
}
</script>

<template>
  <div class="absolute-full relative-position">
    <swiper-container
      class="relative-postition"
      init="false"
      navigation="true"
      pagination="true"
      ref="swiper"
    >
      <swiper-slide
        v-for="(slide, index) in slides"
        class="relative-position window-height"
        :key="`slide-${index}`"
        style="width: 700px; max-width: 80vw"
      >
        <div class="bg-dark fit swiper-material-wrapper">
          <div class="fit swiper-material-content">
            <q-scroll-area class="fit">
              <template v-if="slide.reference">
                <div
                  v-for="(media, i) in slide.reference.media"
                  class="realtive-position"
                  :key="media._key"
                >
                  <template v-if="i === 1">
                    <div class="q-pa-lg">
                      <q-list>
                        <q-item-label class="text-weight-medium">{{
                          slide.reference.title
                        }}</q-item-label>
                        <q-item-label
                          caption
                          class="q-mb-sm text-weight-light"
                          >{{ slide.reference.roles }}</q-item-label
                        >
                        <q-item-label
                          class="q-mb-sm text-caption text-weight-light"
                          >{{ slide.reference.description }}</q-item-label
                        >
                      </q-list>
                      <div
                        class="full-width items-center justify-between q-item__label--caption q-mt-sm row text-caption text-weight-light text-grey"
                      >
                        <div v-if="slide.reference.agency" class="">
                          {{ slide.reference.agency }}
                        </div>
                        <div v-if="slide.reference.freelance" class="">
                          {{ slide.reference.freelance }}
                        </div>
                        <div v-if="slide.reference.company" class="">
                          {{ slide.reference.company }}
                        </div>
                        <div>
                          <span v-if="slide.reference.position"
                            >{{ slide.reference.position }} /</span
                          >
                          {{ slide.reference.year }}
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="media.type === 'image'">
                    <image-component :media="media" />
                  </template>
                  <template v-else>
                    <video-wrapper
                      :active="index === swiperIndex"
                      :project="slide"
                      :src="media.videoUrl"
                    />
                  </template>
                </div>
              </template>
              <template v-else>
                <div
                  v-for="(media, i) in slide.media"
                  class="realtive-position"
                  :key="media._key"
                >
                  <template v-if="i === 1">
                    <div class="bg-white q-pa-lg">
                      <q-list>
                        <q-item-label class="text-weight-medium">{{
                          slide.title
                        }}</q-item-label>
                        <q-item-label
                          caption
                          class="q-mb-sm text-weight-light"
                          >{{ slide.roles }}</q-item-label
                        >
                        <q-item-label
                          class="q-mb-sm text-caption text-weight-light"
                          >{{ slide.description }}</q-item-label
                        >
                      </q-list>
                      <div
                        class="full-width items-center justify-between q-item__label--caption q-mt-sm row text-caption text-weight-light text-grey"
                      >
                        <div v-if="slide.agency" class="">
                          {{ slide.agency }}
                        </div>
                        <div v-if="slide.freelance" class="">
                          {{ slide.freelance }}
                        </div>
                        <div v-if="slide.company" class="">
                          {{ slide.company }}
                        </div>
                        <div>
                          <span v-if="slide.position"
                            >{{ slide.position }} /</span
                          >
                          {{ slide.year }}
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="media.type === 'image'">
                    <image-component :media="media" />
                  </template>
                  <template v-else>
                    <video-wrapper
                      :active="index === swiperIndex"
                      :project="slide"
                      :src="media.videoUrl"
                    />
                  </template>
                </div>
              </template>
            </q-scroll-area>
          </div>
        </div>
      </swiper-slide>
    </swiper-container>
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useQuasar } from "quasar";
import { storeToRefs } from "pinia";
import {
  EffectMaterial,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
} from "src/boot/swiper";
import { useAppStore } from "src/stores/app";
import ImageComponent from "src/components/image/ImageComponent.vue";
import VideoWrapper from "src/components/video/VideoWrapper.vue";

defineOptions({ name: "SwiperComponent" });

const props = defineProps({
  initialSlide: { type: Number, default: 0 },
  slides: { type: Array, default: () => new Array(6).fill({}) },
});

const $q = useQuasar();
const appStore = useAppStore();
const { videoHasSound } = storeToRefs(appStore);
const swiper = ref(null);
const swiperIndex = ref(props.initialSlide);
const videoSound = computed(
  () =>
    props.slides[swiperIndex]?.reference?.videoHasSound ||
    props.slides[swiperIndex]?.videoHasSound ||
    false
);

const params = {
  centeredSlides: true,
  effect: "default",
  grabCursor: false,
  initialSlide: props.initialSlide,
  injectStylesUrls: [
    "/css/pagination-element.scss",
    "/css/navigation-element.scss",
  ],
  keyboard: {
    enabled: true,
  },
  loop: true,
  materialEffect: {
    slideSplitRatio: 0.65,
  },
  modules: [Keyboard, Mousewheel, Navigation, Pagination],
  mousewheel: {
    invert: false,
    forceToAxis: true,
  },
  navigation: true,
  noSwiping: true,
  on: {
    slideChange(swiper) {
      swiperIndex.value = swiper.realIndex;
    },
  },
  pagination: {
    dynamicBullets: true,
  },
  slidesPerView: "auto",
  simulateTouch: false,
  spaceBetween: 24,
};

onMounted(() => {
  Object.assign(swiper.value, params);
  nextTick(() => {
    if (swiper.value) {
      swiper.value.initialize();
    }
  });
});

watch(swiperIndex, (index) => {
  videoHasSound.value = videoSound.value;
});
</script>
